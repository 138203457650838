import useSWR from 'swr';

import type { SupplierResponseDto } from 'dtos';
import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseSuppliersProps = {
  query?: string;
  page?: number;
  pageSize?: number;
  isParent?: boolean;
  sortModel: SortModel;
};

export default function useSuppliers({
  query = '',
  page = 0,
  pageSize = 20,
  isParent,
  sortModel: { sort, sortDirection },
}: UseSuppliersProps) {
  const params = new URLSearchParams();

  params.set('query', query);
  params.set('page', page.toString());
  params.set('pageSize', pageSize.toString());
  params.set('sort', sort);
  params.set('sortDirection', sortDirection);
  isParent !== undefined && params.set('isParent', isParent ? 'true' : 'false');

  const {
    data,
    isValidating: isLoading,
    error,
    mutate: refresh,
  } = useSWR<PaginatedData<SupplierResponseDto>, Error>(
    `suppliers?${params.toString()}`,
    fetcher,
  );

  const { data: suppliers = [], meta } =
    (data as PaginatedData<SupplierResponseDto>) || {};

  return { suppliers, meta, isLoading, error, refresh };
}
