import type { UserProfileResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseAdvisorProps = {
  advisorId?: string;
};

export default function useAdvisor({ advisorId }: UseAdvisorProps) {
  const key = `advisors/${advisorId}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<UserProfileResponseDto, Error>(key, advisorId ? fetcher : null);

  return {
    data: data as UserProfileResponseDto,
    isLoading,
    error,
    refresh: mutate,
  };
}
