import type { CommissionResponseDto, CommissionStatus } from 'dtos';
import useSWR from 'swr';

import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

export type UseTripCommissionsProps = {
  query: string;
  page: number;
  pageSize: number;
  sortModel: SortModel;
  statusFilter?: CommissionStatus;
  bookingId?: string;
};

export default function useTripCommissions({
  query = '',
  page = 1,
  pageSize = 20,
  sortModel: { sort, sortDirection },
  statusFilter,
  bookingId,
}: UseTripCommissionsProps) {
  const params = new URLSearchParams();

  params.set('query', query);
  params.set('page', page.toString());
  params.set('pageSize', pageSize.toString());
  params.set('sort', sort);
  params.set('sortDirection', sortDirection);
  statusFilter && params.set('statusFilter', statusFilter);
  bookingId && params.set('bookingId', bookingId);

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<CommissionResponseDto>, Error>(
    `commissions?${params.toString()}`,
    bookingId ? fetcher : null,
  );

  const { data: commissions = [], meta } =
    (data as PaginatedData<CommissionResponseDto>) || {};

  return { commissions, meta, isLoading, error, refresh: mutate };
}
