import type { TripResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useTrip(tripId?: string, trip?: TripResponseDto) {
  const key = `trips/${tripId}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<TripResponseDto, Error>(key, tripId ? fetcher : null, {
    fallbackData: trip,
  });

  return {
    trip: data as TripResponseDto,
    isLoading,
    error,
    refresh: mutate,
  };
}
