import type { SupplierContactResponseDto } from 'dtos';
import useSWR from 'swr';
import { fetcher } from 'utils/client/swr/jsonFetcher';

import type {
  PaginatedData,
  PaginatedDataResponse,
  SortModel,
} from '../types/paginatedData';

type UseSupplierContactsProps = {
  supplierId: string;
  query: string;
  page: number;
  pageSize: number;
  sortModel: SortModel;
};

export default function UseSupplierContacts({
  supplierId = '',
  query = '',
  page = 1,
  pageSize = 20,
  sortModel: { sort, sortDirection },
}: UseSupplierContactsProps): PaginatedDataResponse<SupplierContactResponseDto> {
  const key = `suppliers/${supplierId}/contacts?query=${query}&page=${page}&pageSize=${pageSize}&sort=${sort}&sortDirection=${sortDirection}`;

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<SupplierContactResponseDto>, Error>(key, fetcher);

  const { data, meta } =
    (response as PaginatedData<SupplierContactResponseDto>) || {};

  return { data, meta, isLoading, error, refresh: () => void mutate() };
}
