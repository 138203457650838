import { type FormResponseDto, FormType } from 'dtos';
import useSWR from 'swr';

import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

export type UseFormsProps = {
  query: string;
  page: number;
  pageSize: number;
  sortModel: SortModel;
};

export default function useForms({
  query = '',
  page = 1,
  pageSize = 20,
  sortModel: { sort, sortDirection },
}: UseFormsProps) {
  const key = `forms?query=${query}&page=${page}&pageSize=${pageSize}&sort=${sort}&sortDirection=${sortDirection}`;

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<FormResponseDto>, Error>(key, fetcher);

  const { data, meta } = (response as PaginatedData<FormResponseDto>) || {};

  return { data, meta, isLoading, error, refresh: mutate };
}
