import type { BookingResponseDto } from 'dtos';
import useSWR from 'swr';
import { fetcher } from 'utils/client/swr/jsonFetcher';

import type { PaginatedData, SortModel } from '../types/paginatedData';

type UseTripBookingsProps = {
  tripId: string;
  query?: string;
  page?: number;
  pageSize?: number;
  getAll?: boolean;
  sortModel?: SortModel;
};

export default function useTripBookings({
  tripId = '',
  query = '',
  page = 0,
  pageSize = 20,
  getAll = false,
  sortModel: { sort, sortDirection } = {
    sort: 'createdAt',
    sortDirection: 'desc',
  },
}: UseTripBookingsProps) {
  const count = getAll ? 100000 : pageSize;

  const key = `trips/${tripId}/bookings?query=${query}&page=${page}&pageSize=${count}&sort=${sort}&sortDirection=${sortDirection}`;

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<BookingResponseDto>, Error>(key, fetcher);

  const { data, meta } = (response as PaginatedData<BookingResponseDto>) || {};

  return { data, meta, isLoading, error, refresh: mutate };
}
