import type { UserInfoResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useUserInfo() {
  const key = 'users/me/info';

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<UserInfoResponseDto, Error>(key, fetcher);

  return {
    userInfo: data as UserInfoResponseDto,
    isLoading,
    error,
    refresh: mutate,
  };
}
