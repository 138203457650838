import type { ClientProfileResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseClientProps = {
  clientId?: string;
  client?: ClientProfileResponseDto;
};

export default function useClient({ clientId, client }: UseClientProps) {
  const key = `clients/${clientId}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<ClientProfileResponseDto, Error>(key, clientId ? fetcher : null, {
    fallbackData: client,
  });

  return {
    client: data as ClientProfileResponseDto,
    isLoading,
    error,
    refresh: mutate,
  };
}
