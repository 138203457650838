import useSWR from 'swr';

import type { AgencyAgreementPaginatedResponseDto } from 'dtos';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseAgencyAgreementsProps = {
  agencyId?: string;
};

const useAgencyAgreements = ({ agencyId }: UseAgencyAgreementsProps) => {
  if (!agencyId)
    return { data: null, isLoading: false, error: null, refresh: () => {} };

  const {
    data,
    isValidating: isLoading,
    error,
    mutate: refresh,
  } = useSWR<AgencyAgreementPaginatedResponseDto, Error>(
    `agency-agreements?agencyId=${agencyId}`,
    fetcher,
  );

  return { data: data?.data, isLoading, error, refresh };
};

export default useAgencyAgreements;
