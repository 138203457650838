import type { BookingResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseBookingProps = {
  bookingId?: string;
};

export default function useBooking({ bookingId }: UseBookingProps) {
  const key = `bookings/${bookingId}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<BookingResponseDto, Error>(key, bookingId ? fetcher : null);

  const clearCacheEntry = () => {
    mutate(undefined, false);
  };

  return {
    data,
    isLoading,
    error,
    refresh: mutate,
    clearCacheEntry,
  };
}
