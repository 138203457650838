import type { SupplierOverviewResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseSupplierReportingOverviewProps = {
  supplierId: string;
};

export default function useSupplierReportingOverview({
  supplierId,
}: UseSupplierReportingOverviewProps) {
  const key = `suppliers/${supplierId}/reporting/overview`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<SupplierOverviewResponseDto, Error>(
    key,
    supplierId ? fetcher : null,
  );

  return {
    supplierReportingOverview: data as SupplierOverviewResponseDto,
    isLoading,
    error,
    refresh: mutate,
  };
}
