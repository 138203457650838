import type { TagResponseListDto, TagType } from 'dtos';
import useSWR from 'swr';
import { fetcher } from '../utils/client/swr/jsonFetcher';

interface UseTagsParams {
  type: TagType;
}

export default function useTags({ type }: UseTagsParams) {
  const qp = new URLSearchParams();

  qp.append('type', type);

  const {
    data,
    isValidating: isLoading,
    error,
    mutate: refresh,
  } = useSWR<TagResponseListDto, Error>(`tags?${qp.toString()}`, fetcher);

  return { tags: data?.data, isLoading, error, refresh };
}
