import type { ClientInvoiceResponseDto } from 'dtos';
import useSWR from 'swr';
import { singleLine } from 'utils/client/formatting';

import type {
  PaginatedData,
  PaginatedDataResponse,
  SortModel,
  UnresolvedPaginatedDataResponse,
} from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseClientInvoicesProps = {
  tripId?: string;
  query?: string;
  page?: number;
  pageSize?: number;
  getAll?: boolean;
  sortModel?: SortModel;
};

export default function useClientInvoices({
  tripId = '',
  query = '',
  page = 0,
  pageSize = 20,
  sortModel: { sort, sortDirection } = {
    sort: 'createdAt',
    sortDirection: 'desc',
  },
}: UseClientInvoicesProps):
  | PaginatedDataResponse<ClientInvoiceResponseDto>
  | UnresolvedPaginatedDataResponse<ClientInvoiceResponseDto> {
  const key = singleLine`/client-invoices?
    query=${query}
    &page=${page}
    &pageSize=${pageSize}
    &sort=${sort}
    &sortDirection=${sortDirection}
    ${tripId ? `&tripId=${tripId}` : ''}`;

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<ClientInvoiceResponseDto>, Error>(key, fetcher);

  const { data, meta } =
    (response as PaginatedData<ClientInvoiceResponseDto>) || {};

  return { data, meta, isLoading, error, refresh: () => void mutate() };
}
