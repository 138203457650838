import type { IdentifierResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseIdentifierProps = {
  identifierId?: string | null;
};

export default function useIdentifier({ identifierId }: UseIdentifierProps) {
  const key = `identifiers/${identifierId}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<IdentifierResponseDto, Error>(key, identifierId ? fetcher : null);

  return {
    identifier: data as IdentifierResponseDto,
    isLoading,
    error,
    refresh: mutate,
  };
}
