import useSWR from 'swr';

import type { LeadStageListResponseDto } from 'dtos';
import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useLeadStages() {
  const {
    data,
    isValidating: isLoading,
    error,
    mutate: refresh,
  } = useSWR<LeadStageListResponseDto, Error>('/lead-stages', fetcher);

  return { data: data?.stages, isLoading, error, refresh };
}
