import type { ClientInvoiceResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseClientInvoiceProps = {
  clientInvoiceId?: string;
};

export default function useClientInvoice({
  clientInvoiceId,
}: UseClientInvoiceProps) {
  const key = `client-invoices/${clientInvoiceId}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<ClientInvoiceResponseDto, Error>(
    key,
    clientInvoiceId ? fetcher : null,
  );

  return {
    clientInvoice: data as ClientInvoiceResponseDto,
    isLoading,
    error,
    refresh: mutate,
  };
}
