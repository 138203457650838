import useSWR from 'swr';

import type { PickerResponseDto } from 'dtos';
import { fetcher } from '../utils/client/swr/jsonFetcher';

export default function useHierarchicalAgencies() {
  const {
    data,
    isValidating: isLoading,
    error,
  } = useSWR<PickerResponseDto, Error>('context/picker', fetcher);

  const { data: agencies, defaultCtx } = (data as PickerResponseDto) || {};

  return { agencies, defaultCtx, isLoading, error };
}
