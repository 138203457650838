import type { BookingResponseDto } from 'dtos';
import useSWR from 'swr';
import { fetcher } from 'utils/client/swr/jsonFetcher';

import type { PaginatedData, SortModel } from '../types/paginatedData';

type UseSupplierBookingsProps = {
  supplierId: string | null | undefined;
  query: string;
  page: number;
  pageSize: number;
  sortModel: SortModel;
};

export default function useSupplierBookings({
  supplierId = null,
  query = '',
  page = 1,
  pageSize = 20,
  sortModel: { sort, sortDirection },
}: UseSupplierBookingsProps) {
  const key = `suppliers/${supplierId}/bookings?query=${query}&page=${page}&pageSize=${pageSize}&sort=${sort}&sortDirection=${sortDirection}`;

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<PaginatedData<BookingResponseDto>, Error>(
    key,
    (supplierId && fetcher) || null,
  );

  const { data, meta } = (response as PaginatedData<BookingResponseDto>) || {};

  return { data, meta, isLoading, error, refresh: mutate };
}
