import { ClerkProvider, SignedIn, SignedOut } from '@clerk/nextjs';
import { CacheProvider, type EmotionCache } from '@emotion/react';
import { Box, Stack, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license-pro';
import SignIn from 'components/SignIn';
import Loader from 'components/common/loader/Loader';
import AppBar from 'components/layout/AppBar';
import Drawer from 'components/layout/Drawer';
import PageContent from 'components/layout/PageContent';
import AppContext from 'context';
import UserInfoContext from 'context/user-info.context';
import { deleteCookie, getCookie, hasCookie, setCookie } from 'cookies-next';
import type { UserInfoResponseDto } from 'dtos';
import LogRocket from 'logrocket';
import { ConfirmProvider } from 'material-ui-confirm';
import moment from 'moment-timezone';
import 'moment-timezone';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { getUserInfo } from 'requests/user-info';

import { SWRConfig, type SWRConfiguration } from 'swr';

import theme from '../theme';
import createEmotionCache from '../theme/createEmotionCache';

//globally set moment to use UTC
moment.tz.setDefault('UTC');

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_PRO_KEY as string);

const clientSideEmotionCache = createEmotionCache();

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache, //for SSR this param is provided by _document.js
  pageProps,
}: AppProps & { emotionCache: EmotionCache }) {
  const router = useRouter();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfoResponseDto | null>(null);

  useEffect(() => {
    const USER_INFO_COOKIE = 'USER_INFO_COOKIE';

    function loadUserInfo(): void {
      getUserInfo()
        .then((newUserInfo) => {
          setCookie(USER_INFO_COOKIE, {
            ...newUserInfo,
            token: newUserInfo.token,
            expires: moment(Date.now()).add(15, 'minutes').toISOString(),
          });
          setUserInfo(newUserInfo);
        })
        .catch(console.error);
    }

    if (!hasCookie(USER_INFO_COOKIE)) {
      loadUserInfo();
    } else {
      const cookie = getCookie(USER_INFO_COOKIE);

      try {
        const userInfo = JSON.parse(cookie as string) as UserInfoResponseDto & {
          expires: string;
        };
        setUserInfo(userInfo);
        if (new Date(userInfo.expires) < new Date()) loadUserInfo();
      } catch (err) {
        deleteCookie(USER_INFO_COOKIE);
        loadUserInfo();
        throw err;
      }
    }
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', () => setIsLoading(true));
    router.events.on('routeChangeComplete', () => setIsLoading(false));
    router.events.on('routeChangeError', () => setIsLoading(false));
  }, [router]);

  if (router.asPath.startsWith('/public')) {
    return (
      <>
        {isLoading && <Loader />}
        <GlobalProviders>
          <Component {...pageProps} />
        </GlobalProviders>
      </>
    );
  }

  const noPaddingViews = [
    '/forms/[id]',
    '/trips/[tripId]/invoices/[id]',
    '/r/[retoolAppId]',
  ];
  const noPadding = noPaddingViews.some((view) => view === router.pathname);

  if (
    process.env.NEXT_PUBLIC_LOGROCKET_APP_ID &&
    typeof window !== 'undefined'
  ) {
    LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID);
  }

  return (
    <>
      <Head>
        <title>TripSuite</title>
      </Head>
      {isLoading && <Loader />}
      <AppContext.Provider value={{ ...pageProps }}>
        <ClerkProvider
          {...pageProps}
          appearance={{
            variables: { colorPrimary: theme.palette.primary.main },
          }}
        >
          <CacheProvider value={emotionCache}>
            <GlobalProviders>
              <SignedIn>
                <UserInfoContext.Provider value={userInfo}>
                  <Stack direction="row">
                    <AppBar open={drawerOpen} setOpen={setDrawerOpen} />
                    <Drawer open={drawerOpen} setOpen={setDrawerOpen} />
                    <PageContent noPadding={noPadding}>
                      <ConfirmProvider
                        defaultOptions={{
                          confirmationButtonProps: {
                            variant: 'contained',
                            color: 'primary',
                          },
                          confirmationText: 'Confirm',
                        }}
                      >
                        <Component {...pageProps} />
                        <Box
                          id="add-button"
                          sx={{
                            position: 'fixed',
                            display: 'flex',
                            justifyContent: 'center',
                            width: 56,
                            bottom: 8,
                            right: 0,
                          }}
                        />
                      </ConfirmProvider>
                    </PageContent>
                  </Stack>
                </UserInfoContext.Provider>
              </SignedIn>
              <SignedOut>
                <SignIn />
              </SignedOut>
            </GlobalProviders>
          </CacheProvider>
        </ClerkProvider>
      </AppContext.Provider>
    </>
  );
}

export default MyApp;

const GlobalProviders = ({ children }: { children: React.ReactNode }) => {
  const swrOptions: SWRConfiguration = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        variant="success"
        hideIconVariant
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SWRConfig value={swrOptions}>{children}</SWRConfig>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
