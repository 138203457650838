import type { CommissionGroupResponseDto } from 'dtos';
import useSWR from 'swr';

import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseCommissionGroupProps = {
  commissionGroupId?: string;
};

export default function useCommissionGroup({
  commissionGroupId,
}: UseCommissionGroupProps) {
  const key = `commission-groups/${commissionGroupId}`;

  const {
    data,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<CommissionGroupResponseDto, Error>(
    key,
    commissionGroupId ? fetcher : null,
  );

  return {
    commissionGroup: data as CommissionGroupResponseDto,
    isLoading,
    error,
    refresh: mutate,
  };
}
