import useSWR from 'swr';

import type {
  CommissionGroupListItemResponseDto,
  CommissionGroupPaginatedResponseDto,
} from 'dtos';
import type {
  PaginatedData,
  PaginatedDataResponse,
  SortModel,
} from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseCommissionGroupsProps = {
  query?: string;
  page?: number;
  pageSize?: number;
  getAll?: true;
  sortModel: SortModel;
};

export default function useCommissionGroups({
  query = '',
  page = 0,
  pageSize = 20,
  getAll,
  sortModel: { sort, sortDirection },
}: UseCommissionGroupsProps): PaginatedDataResponse<CommissionGroupListItemResponseDto> {
  const queryParams = new URLSearchParams();
  queryParams.set('query', query);
  queryParams.set('page', page.toString());
  queryParams.set('pageSize', getAll ? '1000000' : pageSize.toString());
  queryParams.set('sort', sort);
  queryParams.set('sortDirection', sortDirection);

  const {
    data: response,
    isValidating: isLoading,
    error,
    mutate,
  } = useSWR<CommissionGroupPaginatedResponseDto, Error>(
    `commission-groups?${queryParams.toString()}`,
    fetcher,
  );

  const { data, meta } =
    (response as PaginatedData<CommissionGroupListItemResponseDto>) || {};

  return { data, meta, isLoading, error, refresh: () => void mutate() };
}
